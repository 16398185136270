// @flow

import HierarchyItem from 'models/ui/HierarchicalSelector/HierarchyItem';
import useFieldHierarchy from 'components/DataCatalogApp/common/hooks/aqt/useFieldHierarchy';
import type Field from 'models/core/wip/Field';
import type LinkedCategory from 'models/core/wip/LinkedCategory';

export default function useFieldHierarchyRoot(): HierarchyItem<
  LinkedCategory | Field,
> {
  const [fieldHierarchyRoot] = useFieldHierarchy();
  return fieldHierarchyRoot;
}
